import React from 'react';
import '../Productos/Products.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

const Products = () => {
  const products = [
    {
      id: 1,
      name: 'Creación y Desarrollo Sitio Web',
      description: 'Aprende las mejores estrategias de marketing digital.',
      price: 'Desde $70.000',
      image: './img/CreacionWeb.webp'
    },
    {
      id: 2,
      name: 'Aplicaciones Móviles',
      description: 'Desarrollo de aplicaciones móviles para tu empresa.',
      price: 'Desde $70.000',
      image: './img/Aplicaciones.webp'
    },
    {
      id: 3,
      name: 'Chatbots y Asistentes Virtuales',
      description: 'Automatiza la atención al cliente de tu empresa.',
      price: 'Desde $70.000',
      image: './img/Chatbot.webp'
    },
    {
      id: 4,
      name: 'Asesoría y Gestión Sitio Web',
      description: 'Optimiza tu sitio web para los motores de búsqueda.',
      price: 'Desde $15.000',
      image: './img/asesoriaweb.webp'
    },
    {
      id: 5,
      name: 'Contenido Redes Sociales',
      description: 'Personaliza tus redes sociales con estas plantillas.',
      price: '$6.000 - 3 plantillas',
      image: './img/contenidoredes.webp'
    },
    {
      id: 6,
      name: 'Asesoría Redes Sociales',
      description: 'Administración profesional de tus redes sociales.',
      price: '$15.000',
      image: './img/gestionredes.webp'
    },
    {
      id: 7,
      name: 'Campañas Publicitarias',
      description: 'Creación y manejo de publicidad en redes sociales.',
      price: 'Desde $25.000',
      image: './img/campañasredes.webp'
    },
    {
      id: 8,
      name: <>Análisis<br />de Métricas</>,
      description: 'Informe del rendimiento de tus redes sociales.',
      price: '$15.000',
      image: './img/analisismetricas.webp'
    },
    {
      id: 9,
      name: <>Diseño <br />Gráfico</>,
      description: 'Diseño de logos, banners, tarjetas de presentación, etc.',
      price: 'Desde $10.000',
      image: './img/diseño.webp'
    }
  ];

  return (
    <div>
      <Navbar />
      <section className="product-section-products">
        <h2 className="section-title-products">Nuestros Productos Digitales</h2>
        <div className="product-list-products">
          {products.map((product) => (
            <div key={product.id} className="product-card-products">
              <div className="image-container-products">
                <img src={product.image} alt={product.name} className="product-image-products" />
              </div>
              <h3 className="product-name-products">{product.name}</h3>
              <p className="product-description-products">{product.description}</p>
              <span className="product-price-products">{product.price}</span>
              <button className="buy-button-products">Comprar</button>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Products;
