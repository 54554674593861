import React, { useEffect, useState } from 'react';
import '../Servicios/Servicios.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

// Importa los datos desde el archivo JSON
import servicesData from '../Servicios/servicesData.json';

const ServiceCard = ({ title, description, icon }) => (
    <div className="card-service">
        <h2>{title}</h2>
        <p>{description}</p>
        <img src={icon} alt="Icon" className="icon-service" />
    </div>
);

const CtaCard = ({ title, description, icon, buttonText }) => (
    <div className="cta-card-service">
        <h2>{title}</h2>
        <p>{description}</p>
        <div className="icon-container">
            {icon && <img src={icon} alt="Icono" className="icon-asesoria" />}
        </div>
        <button className="cta-button-service">{buttonText}</button>
    </div>
);

const Servicios = () => {
    const [serviceCards, setServiceCards] = useState([]);
    const [ctaCard, setCtaCard] = useState({});
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

    useEffect(() => {
        // Carga los datos del JSON en el estado del componente
        setServiceCards(servicesData.serviceCards);
        setCtaCard(servicesData.ctaCard);

        // Escuchar cambios en el tamaño de la ventana
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Limpiar el event listener cuando el componente se desmonte
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className='service-all'>
            <Navbar />

            <div className="container-service">
                <div className="left-section-service">
                    <div className="text-content-service">
                        <h1 className="title-service">Plan de Acompañamiento y Asesoría</h1>
                        <p className="description-service">
                            Te acompañamos y enseñamos lo que necesitas para poder aprovechar al máximo tu negocio o emprendimiento...
                        </p>
                        <button className="more-button-service">Más</button>
                    </div>
                    <img
                        src="./img/ceo 2 sin fondo.webp"
                        alt="Asesor"
                        className="image-service"
                    />
                </div>

                {/* Si es vista móvil, agrupa las cards en tres contenedores */}
                {isMobileView ? (
                    <div className="mobile-cards-container">
                        <div className="left-container">
                            {serviceCards.slice(0, 2).map((card, index) => (
                                <ServiceCard
                                    key={index}
                                    title={card.title}
                                    description={card.description}
                                    icon={card.icon}
                                />
                            ))}
                        </div>
                        <div className="right-container">
                            {serviceCards.slice(2, 4).map((card, index) => (
                                <ServiceCard
                                    key={index}
                                    title={card.title}
                                    description={card.description}
                                    icon={card.icon}
                                />
                            ))}
                        </div>
                        <div className="full-width-container">
                            <CtaCard
                                title={ctaCard.title}
                                description={ctaCard.description}
                                icon={ctaCard.icon}
                                buttonText={ctaCard.buttonText}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="right-section-service">
                        {serviceCards.map((card, index) => (
                            <ServiceCard
                                key={index}
                                title={card.title}
                                description={card.description}
                                icon={card.icon}
                            />
                        ))}
                        <CtaCard
                            title={ctaCard.title}
                            description={ctaCard.description}
                            icon={ctaCard.icon}
                            buttonText={ctaCard.buttonText}
                        />
                    </div>
                )}
            </div>

            <Footer />
        </div>
    );
};

export default Servicios;
