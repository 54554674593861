import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Home/Home';
import NuestraEmpresa from './NuestraEmpresa/NuestraEmpresa';
import Asesoria from './Asesoria/Asesoria';
import Contacto from './Contacto/Contacto';
import Servicios from './Servicios/Servicios';
import Products from './Productos/Products';

function App() {
  return (
    <Router>
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quienes-somos" element={<NuestraEmpresa />} />
          <Route path="/asesoria" element={<Asesoria />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/productos" element={<Products />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
