import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import { FaBars } from 'react-icons/fa';

const Navbar = ({ onToggleMenu }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Detecta si la vista es móvil o escritorio
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Si el ancho es menor o igual a 768px, es móvil
    };

    // Revisa el tamaño de la pantalla cuando el componente se monta y cuando se redimensiona
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  // Función para alternar el estado del menú
  const toggleMenu = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    
    // Verifica si onToggleMenu es una función antes de llamarla
    if (onToggleMenu) {
      onToggleMenu(newState);
    }
  };

  // Función para manejar el clic en "Inicio"
  const handleHomeClick = () => {
    if (isMobile) {
      toggleMenu(); // En móvil, también alterna el menú
    }
    // En escritorio solo redirige al home, no hace nada más
  };

  return (
    <nav className="navbar">
      <a href="/">
        <img src="img/logo3-empresa.webp" alt="Logo" className="logo-empresa" />
      </a>

      {/* Ícono del menú hamburguesa para vista móvil */}
      <div className="menu-icon" onClick={toggleMenu}>
        <FaBars />
      </div>

      {/* Menú desplegable para vista móvil */}
      <div className={`nav-links ${isOpen ? 'active' : ''}`}>
        <NavLink to="/" exact className="nav-link" activeClassName="active-link" onClick={handleHomeClick}>
          Inicio
        </NavLink>
        <NavLink to="/servicios" className="nav-link" activeClassName="active-link" onClick={toggleMenu}>
          Servicios
        </NavLink>
        <NavLink to="/productos" className="nav-link" activeClassName="active-link" onClick={toggleMenu}>
          Productos
        </NavLink>
        <NavLink to="/quienes-somos" className="nav-link" activeClassName="active-link" onClick={toggleMenu}>
          Nuestra Empresa
        </NavLink>
        <NavLink to="/contacto" className="nav-link" activeClassName="active-link" onClick={toggleMenu}>
          Contacto
        </NavLink>
      </div>
    </nav>
  );
};

export default Navbar;
