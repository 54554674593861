import React from 'react';
import { NavLink } from 'react-router-dom';
import './Contacto.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

const Contacto = () => {
  return (
    <div>
      <Navbar />
      <section className="contactanos-section-contact">
        <div className="container-contact">
          <div className="contactanos-content-contact">
            <div className="contactanos-title-contact">
              <h1>Contáctanos</h1>
            </div>
            <div className="contactanos-body-contact">
              <div className="contactanos-image-contact">
                <img src="img/contact-ceos.webp" alt="Personas trabajando" />
              </div>
              <div className="form-card-contact">
                <h2>Escríbenos Tus Dudas</h2>
                <form>
                  <input type="text" placeholder="Mensaje..." className="form-input-contact" />
                  <div className="email-container-contact">
                    <input type="email" placeholder="Ingresa tu email..." className="email-input-contact" />
                    <button type="submit" className="submit-button-contact">Enviar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contacto;
