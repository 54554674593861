import React from 'react';
import '../Asesoria/Asesoria.css';

const Asesoria = () => {
  return (
    <div className="asesoria">
      <h1>Asesoría</h1>
      <p>Información sobre nuestros servicios de asesoría...</p>
    </div>
  );
};

export default Asesoria;
